import Video from "./Video";

const IconsInfo = () => {
  return (
    <>
      <div class="container-fluid containerIconsInfo">
        <div class="gridIcons row">
          <div class="col-12 col-sm-4 col-md-4">
            <img class="iconEvent pb-4" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/informacion/Ico_Asistentes.png" alt="logoBbmundo" width="500" height="auto" />
            <h1 class="quantityEvent">500</h1>
            <p class="descriptionIconEvent mb-0">Mamás y papás</p>
            <p class="descriptionIconEvent bold mb-0">aprendiendo</p>
          </div>
          <div class="col-12 col-sm-4 col-md-4">
            <img class="iconEvent pb-4" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/informacion/Ico_Conferencias.png" alt="logoBbmundo" width="500" height="auto" />
            <h1 class="quantityEvent">10</h1>
            <p class="descriptionIconEvent bold mb-0">Conferencias</p>
            <p class="descriptionIconEvent mb-0"> y 1 taller</p>
          </div>
          <div class="col-12 col-sm-4 col-md-4">
            <img class="iconEvent pb-4" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/informacion/Ico_GoodieBag.png" alt="logoBbmundo" width="500" height="auto" />
            <h1 class="quantityEvent">1</h1>
            <p class="descriptionIconEvent bold mb-0">Goodie bag</p>
          </div>
        </div>
      </div>
      <Video/>
    </>
  )
}
export default IconsInfo;