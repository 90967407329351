import Aliados from "./Aliados";

const Horarios = () => {
    return (
        <>
            <section id="agenda" class="agendaV2" name="agenda">
                <div class="container-fluid containerTable">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="titleSection text-capitalize text-center mb-5">HORARIOS</h1>
                            <div class="table-responsive">
                                <table class="table mb-0 table-borderless">
                                    <tbody>
                                        <tr class="rowBgDarkBlue mainRow text-light">
                                            <th class="text-white textTitleTable1 textTableVariantDateTable text-left borderRight" colspan="2" scope="col">7:30 - 8:30 </th>
                                            <th class=" text-white textTitleTable1 textTableVariantDateTable  text-center" colspan="12" scope="col"><span>REGISTRO</span></th>
                                        </tr>

                                        <tr class="rowBgLightBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden" aria-expanded="false" aria-controls="rowHidden">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">8:30 - 9:00 </th>
                                            <th class=" textTitleTable textTableVariantDateTable  text-center" colspan="10" scope="col"><span>Diana Arreola:</span> Criar en presencia</th>
                                        </tr>
                                        <tr class="rowBgLightBlue collapse" id="rowHidden">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">Ajusta las expectativas, deja atrás la culpa y fortalece los vínculos. Aprende a vivir en presencia para conectar más contigo y con tus hijos.</th>
                                        </tr>


                                        <tr class="rowBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden9" aria-expanded="false" aria-controls="rowHidden9">
                                            <th class=" textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">9:05 - 10:05 </th>
                                            <th class="  textTitleTable textTableVariantDateTable text-center" colspan="10" scope="col"><span>Regina Ascencio:</span> Autoestima y confianza: La clave para una familia feliz</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden9">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">Mamás y papás necesitan formar a sus hijos con cariño y sin frustración,  y lo pueden hacer a través de un método probado con base en el neurodesarrollo infantil.
                                            </th>
                                        </tr>



                                        <tr class="rowBgLightBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden3" aria-expanded="false" aria-controls="rowHidden3">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">10:10 - 10:40 </th>
                                            <th class="textTitleTable textTableVariantDateTable  text-center" colspan="10" scope="col"><span>Jorge Alejandro Michel:</span> ¿Cómo desbloquear los beneficios de las vitaminas esenciales?
                                            </th>
                                        </tr>
                                        <tr class="rowBgLightBlue collapse" id="rowHidden3">
                                            <th class="textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">Qué tanto cuides tu salud antes, durante y después del embarazo afecta o beneficia la vida de tu hijo, ¿sabrías cuántas vitaminas tomar para evitar riesgos en el desarrollo? </th>
                                        </tr>


                                        <tr class="rowBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden4" aria-expanded="false" aria-controls="rowHidden4">
                                            <th class=" textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">10:45 - 11:45 </th>
                                            <th class=" textTitleTable textTableVariantDateTable text-center" colspan="10" scope="col"><span>Sopitas:</span> Ser papá de una niña</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden4">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">Rutinas, juegos, espacios y más es lo que vuelve diferente el tipo de crianza con niños y niñas, pero  papás ¿alguien les advirtió de cómo iban a sentirse al ver a una bebé por primera vez?
                                            </th>
                                        </tr>


                                        <tr class="rowWhite">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight align-middle" colspan="2" scope="col">11:50 - 12:00 </th>
                                            <th class=" textTitleTable textTableVariantDateTable  text-center" colspan="12" scope="col">
                                                <img
                                                    width="600"
                                                    height="auto"
                                                    loading="lazy"
                                                    class="img-fluid px-0 px-md-3 imgAgenda"
                                                    src="https://masterbbmundo.s3.amazonaws.com/Agenda/Palabra_Receso.webp"
                                                    alt="receso"
                                                />
                                            </th>
                                        </tr>



                                        <tr class="rowBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden5" aria-expanded="false" aria-controls="rowHidden5">
                                            <th class=" textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">12:05 - 12:35 </th>
                                            <th class=" textTitleTable textTableVariantDateTable  text-center" colspan="10" scope="col"><span>Marcela Escalera:</span> Cómo influye la infancia que tuve en mi tipo de crianza</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden5">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">
                                                Usa tu infancia para transformar tu tipo de crianza, y así evites caer en frustración por no “cumplir” las expectativas de la maternidad y la forma en que educas a tu hijo, ten más compasión con tu historia
                                            </th>
                                        </tr>


                                        <tr class="rowBgLightBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden6" aria-expanded="false" aria-controls="rowHidden6">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">12:40 - 13:10 </th>
                                            <th class="textTitleTable textTableVariantDateTable text-center" colspan="10" scope="col"> <span>Benjamín Zepeda Ortega:</span> Pequeños respiros: Entendiendo las Alergias Infantiles</th>
                                        </tr>
                                        <tr class="rowBgLightBlue collapse" id="rowHidden6">
                                            <th class="textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">
                                            No todo es alergia y no todo es infección respiratoria, por eso es importante entender los 3 tipos de alergia más comunes y qué es lo que las ocasiona.
                                            </th>
                                        </tr>



                                        <tr class="rowBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden7" aria-expanded="false" aria-controls="rowHidden7">
                                            <th class=" textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">13:15 - 14:15 </th>
                                            <th class=" textTitleTable textTableVariantDateTable  text-center" colspan="10" scope="col"><span>Julia Borbolla:</span>  Infancia sin heridas, aprende a prevenir abuso sexual</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden7">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">Cada año, 5.4 millones de niños, niñas y adolescentes son víctimas de abuso sexual en México y lo más grave es que el agresor podría estar en casa. Identifica las <i>red flags</i> y los comportamientos en tus hijos.
                                            </th>
                                        </tr>


                                        <tr class="rowWhite">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight align-middle" colspan="2" scope="col">14:20 - 15:20 </th>
                                            <th class=" textTitleTable textTableVariantDateTable  text-center" colspan="12" scope="col">
                                                <img
                                                    width="600"
                                                    height="auto"
                                                    loading="lazy"
                                                    class="img-fluid px-0 px-md-3 imgAgenda"
                                                    src="https://masterbbmundo.s3.amazonaws.com/Agenda/comida.webp"
                                                    alt="receso"/>
                                            </th>
                                        </tr>


                                        <tr class="rowBgLightBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden8" aria-expanded="false" aria-controls="rowHidden8">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">15:25 - 16:25 </th>
                                            <th class=" textTitleTable textTableVariantDateTable  text-center" colspan="10" scope="col"><span>Felipe Hernández:</span> Taller Primeros Auxilios</th>
                                        </tr>
                                        <tr class="rowBgLightBlue collapse" id="rowHidden8">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">¿Sabrías cómo reaccionar si tu hijo se atraganta o tiene alguna emergencia? No dejes que el miedo te paralice, mejor aprende distintas técnicas para saber cómo salvarle la vida.</th>
                                        </tr>


                                        <tr class="rowBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden2" aria-expanded="false" aria-controls="rowHidden2">
                                            <th class=" textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">16:30 - 17:10 </th>
                                            <th class=" textTitleTable textTableVariantDateTable text-center" colspan="10" scope="col"><span>Bárbara Anderson:</span> Distintos tipos de maternidad</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden2">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">
                                                Ser mamá nunca ha sido como un cuento de hadas, pero cuando la salud de uno de tus hijos necesita más atenciones, los retos aumentan.
                                            </th>
                                        </tr>


                                        <tr class="rowBgLightBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden10" aria-expanded="false" aria-controls="rowHidden10">
                                            <th class="textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">17:15 - 17:45 </th>
                                            <th class="textTitleTable textTableVariantDateTable text-center" colspan="10" scope="col"><span>Antonio Rizzoli:</span> Problemas de atención y rendimiento escolar</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden10">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">
                                                A veces no se trata de decir “mi hijo no aprende”, porque podría ser un problema más allá de si copia bien la tarea o sigue de corrido el dictado. Los problemas de rendimiento escolar son muy comunes pero mal atendidos.
                                            </th>
                                        </tr>


                                        <tr class="rowBlue mainRow" data-bs-toggle="collapse" data-bs-target="#rowHidden11" aria-expanded="false" aria-controls="rowHidden11">
                                            <th class=" textTitleTable textTableVariantDateTable text-left borderRight" colspan="2" scope="col">17:50 - 18:50 </th>
                                            <th class="  textTitleTable textTableVariantDateTable text-center" colspan="10" scope="col"><span>Vidal Schmill:</span> Límites para una discliplina inteligente</th>
                                        </tr>
                                        <tr class="rowBlue collapse" id="rowHidden11">
                                            <th class=" textTitleTable textTableVariantDateTable  text-center border-top border-white" colspan="13" scope="col">¿Te pasa que tu hijo no te escucha cuando lo corriges o hasta te grita? Podrías no tener la autoridad suficiente, por eso aprende a poner límites claros para formar mejores vínculos.
                                            </th>
                                        </tr>


                                        <tr class="rowBgTranslucid mainRow">
                                            <th class="textTitleTable textTableVariantDateTable  text-center" colspan="14" scope="col">* La agenda puede cambiar sin previo aviso.</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Aliados/>
        </>
    )
}
export default Horarios;