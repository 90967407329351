import Footer from "./Footer";

const Faqs = () => {
    return (
        <>
            <section id="faqs" name="faqs">
                <div class="container-fluid px-0">
                    <div class="row mx-auto">
                        <div class="col-12 faq__main_bg_1">
                            <div class="d-flex align-items-center justify-content-center">
                                <h2 class="faq__title d-inline mb-0 generalTitle">FAQ'S</h2>
                            </div>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                        <button
                                            class="p-4 text-left p-3 accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseOne"
                                        >
                                            <strong class="bodoni pe-2 number">1.- </strong>¿Qué es Master bbmundo?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseOne"
                                        class="accordion-collapse collapse p-5"
                                        aria-labelledby="flush-headingOne"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div class="text-black accordion-body">
                                            Un día con 10 conferencia, 1 taller y muchas sorpresas más. En Master bbmundo “Nacer como papás, crecer como familia" aprenderán a poner en práctica todas las herramientas que nos darán los mejores especialistas. Recuerden que no importa si están juntos, separados o solteros, los niños necesitan personas que los amen y, sobre todo, los eduquen para ser adultos funcionales.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                        <button
                                            class="p-4 text-left p-3 accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseTwo"
                                        >
                                            <strong class="bodoni pe-2 number">2.- </strong>¿Para qué ir a Master bbmundo?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseTwo"
                                        class="accordion-collapse collapse p-5"
                                        aria-labelledby="flush-headingTwo"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div class="text-black accordion-body">
                                            No hay mamás, papás o familias perfectas. Lo único que podemos aprender es qué heridas necesitamos sanar, cuáles son los detonadores de cada una de nuestras emociones y cómo éstas pueden afectar a nuestros hijos.
                                        </div>
                                        <div class="text-black accordion-body">
                                            Juntamos a 11 de nuestros grandes especialistas para hablar de distintos temas y vivencias para que juntos, como papás y mamás, sepan que alguien más podría estar viviendo lo mismo que ustedes y que todos somos capaces de llevar una crianza integral y más consciente.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                        <button
                                            class="p-4 text-left p-3 accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseFour"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseFour"
                                        >
                                            <strong class="bodoni pe-2 number">3.- </strong>¿Puedo llevar a mi hijo?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseFour"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingThree"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div class="text-black accordion-body">
                                            Aunque es mejor asistir sin niños para regalarse un momento como papás y aprender, sabemos que las dinámicas de cada familia son distintas, así que contaremos con nanitas que estarán al pendiente de los niños, cuidando que tengan actividades divertidas.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingFour">
                                        <button
                                            class="p-4 text-left p-3 accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseFive"
                                            aria-expanded="false"
                                            aria-controls="flush-collapseFive"
                                        >
                                            <strong class="bodoni pe-2 number">4.- </strong> ¿Hay estacionamiento?
                                        </button>
                                    </h2>
                                    <div
                                        id="flush-collapseFive"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingFour"
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div class="text-black accordion-body">
                                            No hay estacionamiento, pero hay parquímetros en toda la zona, puedes encontrar algún lugar si llegas con tiempo. También hay estacionamientos públicos para distintos presupuestos.
                                            La estación del metro más cercano es Sevilla, de la línea rosa.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Faqs;