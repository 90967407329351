const Espacialistas = () => {
    return (
        <>
            <section id="especialistas" class="container-fluid px-0" name="especialistas">
                <div id="carouselExampleCaptions" class="carousel slide especialistas carousel-fade" data-bs-ride="carousel">
                    <h1 class="titleVideo text-center mb-0 px-5 pt-3">SPEAKERS</h1>

                    <div class="carousel-inner container-xl">


                        <div class="carousel-item active" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/DianaArreola.png" alt="Diana Arreola" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Diana Arreola</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Criar en presencia </p>
                                        <p class="descriptionEspecialista">Experta en atención a niños y adolescentes. Tiene formación en <i>Building Personal Resilience </i>por la Universidad de Harvard y es creadora del programa “Papalote”, para transformar la crianza de miles de familias, promoviendo una perspectiva consciente y fortaleciendo los vínculos a través de ejercicios. Autora del cuento infantil “Mateo y Carlota” que se centra en la regulación emocional en la infancia.</p>

                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Barbara.png" alt="Bárbara Anderson" width="900" height="auto" />

                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Bárbara Anderson</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Distintos tipos de maternidad</p>
                                        <p class="descriptionEspecialista">Activista por los derechos de las personas con discapacidad, tema en el que ha logrado impulsar cambios legales trascendentales en materia de la inclusión a través del sitio de noticias "Yo También". Es autora de dos libros sobre discapacidad, uno de los cuales tendrá su versión en Netflix.</p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Sopitas_1.png" alt="Francisco Alanís" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Francisco Alanís "Sopitas"</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Ser papá de una niña</p>
                                        <p class="descriptionEspecialista">Es papá, comunicador, escritor, locutor y fundador del portal "Sopitas", el cual innovó la forma de hacer contenido en internet en México.
                                        </p>

                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Felipe.png" alt="Felipe Hernández" width="900" height="auto" />

                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Felipe Hernández</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Taller:</span> Primeros Auxilios</p>
                                        <p class="descriptionEspecialista"> Director general de Grupo Salvando Vidas, experto en Primeros Auxilios. Colaborador en la Alianza Contra la Muerte Súbita Cardíaca en México una Iniciativa de la Sociedad Interamericana de Cardiología. También es instructor de RCP avalado por la Sociedad Interamericana de Cardiología y coordinador de Centros de Entrenamiento en RCP avalados por la Alianza Contra la Muerte súbita cardíaca en México.
                                        </p>

                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Julia.png" alt="Julia Borbolla" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Julia Borbolla</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Infancia sin heridas, aprende a prevenir abuso sexual.</p>
                                        <p class="descriptionEspecialista"> Psicóloga con una trayectoria de 40 años, es fundadora y creadora del programa "Escudo de la dignidad" que ha beneficiado a más de un millón de niñas y niños. Desde 2005 es fundadora y presidenta de la Asociación Civil “Antenas por los niños A.C”, que trabaja por la salud mental, la justicia infantil y tienen presencia en centros de justicia en nueve estados de la República Mexicana.</p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Vidal.png" alt="Vidal Schmill" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Vidal Schmill</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Límites para una disciplina inteligente</p>
                                        <p class="descriptionEspecialista">
                                            Pedagogo, especialista en Desarrollo Humano y conferencista internacional. Pionero del concepto Escuela para padres en México y fundador de la plataforma de aprendizaje familiar con el mismo nombre. Autor de siete libros sobre educación infantil y adolescencia.
                                        </p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/JorgeVergara.png" alt="Vidal Schmill" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Jorge Alejandro Michel</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> ¿Cómo desbloquear los beneficios de las vitaminas esenciales?                                            </p>
                                        <p class="descriptionEspecialista">
                                        Doctor experto en ginecología y obstetricia (UDG) certificado por CMGO. Cuenta con un master en Reproducción Humana y con maestría en Bioética. También es Director de CERFERTIL y profesor de medicina de la Universidad de Guadalajara. Ex-presidente del Colegio de Gineco-Obstetras del estado de Jalisco.
                                        </p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/MarcelaEscalera.webp" alt="Marcela Escalera" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Marcela Escalera</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Cómo influye la infancia que tuve en mi tipo de crianza</p>
                                        <p class="descriptionEspecialista">
                                            Psicóloga con Master en Educación con más de 30 años de experiencia enfocada en crianza evolutiva y creadora del diplomado "Crianza con vínculo". Desde 2009 es directora del Instituto Neufeld en Español a nivel internacional.
                                        </p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/AntonioRizolli.webp" alt="Antonio Rizzoli" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Antonio Rizzoli</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Problemas de atención y rendimiento escolar</p>
                                        <p class="descriptionEspecialista">
                                        Psicólogo y especialista en Pediatría y Neurología Pediatrica. Maestro y doctor en ciencias por la UNAM. Egresado del programa SQIL en Harvard Medical School. Fundador de la especialidad de Pediatría del desarrollo por la UNAM, primera en su tipo en América Latina. Presidente fundador de la Sociedad Mexicana de Pediatría del Desarrollo y la Conducta.
                                        </p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/BenjaminZepeda+2.png" alt="Benjamín Zepeda Ortega" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Benjamín Zepeda Ortega</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span> Pequeños Respiros: Entendiendo las Alergias Infantiles</p>
                                        <p class="descriptionEspecialista">
                                        Experto en Alergia Pediátrica y Alergia e Inmunología Clínica Pediátrica, cuenta con más de 10 años de experiencia y es presidente del Colegio Mexicano de Pediatras Especialistas en Inmunología Clínica y Alergia. 
                                        </p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="carousel-item" data-bs-interval="10000">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="circleEspecialistas">
                                        <img class="bgEspecialista position-relative" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Back_speakers.webp" alt="background" width="500" height="auto" />
                                        <img class="especialistaImg" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/Regina.webp" alt="Regina Ascencio" width="900" height="auto" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-7 d-flex flex-column">
                                    <div class="carousel-caption">
                                        <h1 class="nameEspecialista">Regina Ascencio</h1>
                                        <p class="temaEspecialista pt-4">
                                            <span>Conferencia:</span>Autoestima y confianza: La clave para una familia feliz</p>
                                        <p class="descriptionEspecialista">
                                        Pedagoga, neuropsicóloga y creadora de contenido de crianza respetuosa. Tiene más de 10 años de experiencia en instituciones educativas, desarrollando el estilo y modelo educativo y asesorando en temas de infraestructura y espacios de aprendizaje.
                                        </p>
                                    </div>
                                    <div class="btnsSlide mt-5">
                                        <button class="carousel-control-prev text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <span class="visually-hidden">Previous</span>
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_izquierda.webp" alt="Anterior" width="70px" height="auto" />
                                        </button>
                                        <button class="carousel-control-next text-uppercase pt-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <img src="https://masterbbmundo.s3.amazonaws.com/Speaker/Flecha_derecha.webp" alt="Siguiente" width="70px" height="auto" />
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>
        </>
    )
}
export default Espacialistas;