
import { Route, Routes, useLocation } from 'react-router-dom';
import Main from "../Components/Main";
import Terminos from "../Components/Terminos";
import Aviso from "../Components/Aviso";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useState } from 'react';
export const AppRouter = () => {
    const [preview, setPreview] = useState(true);
    const location = useLocation();
    return (
        <>
        {
            preview ?
            <>
                <img src="https://masterbbmundo.s3.amazonaws.com/Home/NosVemos_2025.jpg" className="d-none d-md-block preview" alt="Preview Master Bbmundo" />
                <img src="https://masterbbmundo.s3.amazonaws.com/Home/NosVemos_2025_movil.jpg" className="d-block d-md-none preview px-4" alt="Preview Master Bbmundo" />
            </>
            :
            <>
                <Header />
                <Routes location={location} key={location.pathname}>
                    <Route path="/*" element={<Main />} />
                    <Route path="/avisoDePrivacidad" element={<Aviso />} />
                    <Route path="/terminosYcondiciones" element={<Terminos />} />
                </Routes>
                <Footer /> 
            </>
        }


        </>
    )
}
export default AppRouter;