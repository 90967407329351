const Aliados = () => {
    return (
        <>
            <section class="aliados  container-fluid px-0">
                <div class="container-fluid">
                    <h1 class="text-center text-dark title__aliados text-capitalize pb-5  generalTitle">NUESTROS ALIADOS</h1>
                </div>

                <div class="container__sponsors container d-flex justify-content-center align-content-center pt-2">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
                            <a rel="noreferrer" target="_blank" href="https://aloevida.com.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoAloevida.png"
                                    alt="Aloevida"
                                    width="180"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.frutevia.com/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoFrutevia.png"
                                    alt="Frutevia"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://galletaslili.com/inicio/">
                                <img
                                    class=" logoFooterAliados px-5 aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoLili.png"
                                    alt="Lili"
                                    width="230"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.bamboolifestyle.com.mx/">
                                <img
                                    class=" logoFooterAliados px-5 aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Logo_Bamboo.png"
                                    alt="Bamboo Lifestyle"
                                    width="250"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.sopharma.mx/">
                                <img
                                    class=" logoFooterAliados px-5 aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Sopharma_blanco-01.webp"
                                    alt="sopharma"
                                    width="250"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.pocket.com.mx/">
                                <img
                                    class=" logoFooterAliados px-5 aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoPocket.png"
                                    alt="Pocket"
                                    width="250"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.torongia.com/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoTorongia.png"
                                    alt="Torongia"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://web.azor.com.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoAzor.png"
                                    alt="Azor"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://berrynuts.mx/">
                                <img
                                    class=" logoFooterAliados px-5 aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoBerryNuts.png"
                                    alt="Berry Nuts"
                                    width="230"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.kefirnutricion.com.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoLifeway.png"
                                    alt="Lifeway"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://mx.fage/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoFage.png"
                                    alt="Fage"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://moyo.another.co/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoMoyo.png"
                                    alt="Moyo"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>

                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://distritobotana.com/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoDistritoBotana.png"
                                    alt="Distrito Botana"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.omnipal.com.mx/index.php">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoOmnipal.png"
                                    alt="Omnipal"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.maison-kayser.com.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/LogoMaysonKayser.png"
                                    alt="Maison Kayser"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.suzanneolivier.com/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Suzanne.webp"
                                    alt="Maison Kayser"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://xilinat.com/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Xilinat.webp"
                                    alt="Maison Kayser"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://bonafont.com.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Logo_Bonafont.png"
                                    alt="Bonafont"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.agenday.com.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Logo_Agenday.png"
                                    alt="Agenday"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.krispykreme.mx/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Logo_Krispy.png"
                                    alt="Krispy"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="offset-0 offset-lg-3 col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <a rel="noreferrer" target="_blank" href="https://www.minunu.com/">
                                <img
                                    class=" logoAliados aliadosHov"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Aliados/Logo_MiNunu.png"
                                    alt="MiNunu"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center pt-2">
                            <img
                                class=" logoAliados aliadosHov arroz"
                                loading="lazy"
                                src="https://masterbbmundo.s3.amazonaws.com/Aliados/arroz_con_leche.png"
                                alt="MiNunu"
                                width="150"
                                height="auto"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Aliados;