const Terminos = () => {
    return (
        <>
            <section id="terms" class="pt-5 pb-5">
                <div class="container px-5 mt-5">
                    <div class="row mx-auto">
                        <div class="col-12 offset-lg-1 col-lg-10 my-4 d-flex flex-column justify-content-center align-items-center">
                            <img class="py-4 logoHome" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Speaker/LogoMbb.svg" alt="logoBbmundo" width="200" height="auto"/>
                                <h1 class="my-4 text-center text-secondary pt-5 title">Términos y Condiciones</h1>
                                <p class="pb-4 pt-5"><strong>Primero. Lugar y Fecha.</strong> El evento denominado “Master bbmundo” se realizará el 5 de octubre, empezando el registro a las 7:30 am en
                                    ESPACIO DURANGO, Calle de Durango 275, Roma Nte, Cuauhtémoc, 06700, CDMX.</p>
                                <p class="pb-4">Evento sujeto a cambios sin previo aviso.</p>
                                <p class="pb-4"><strong>Segundo. Precio y Forma de Pago.</strong> El costo para asistir al evento se dividirá en 4 fases</p>
                                <div class="table-responsive w-100">
                                    <table class="table mt-5 mb-5">
                                        <thead>
                                            <tr class="header-table">
                                                <th scope="col">Fase</th>
                                                <th colspan="2" scope="col">Precios</th>
                                                <th scope="col">Fechas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Precio Preventa</th>
                                                <td>$900.00 (novecientos pesos 00/100 MN) por persona</td>
                                                <td></td>
                                                <td>Del 1 de agosto al 15 de agosto del 2024</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Fase 1</th>
                                                <td>$1050.00 (mil cincuenta pesos 00/100 MN) por persona</td>
                                                <td>$1950.00 (mil novecientos cincuenta pesos 00/100 MN) por pareja</td>
                                                <td>Del 16 de agosto al 15 de septiembre</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Fase 2</th>
                                                <td>$1150.00 (mil ciento cincuenta pesos 00/100 MN) por persona</td>
                                                <td>$2160.00 (dos mil ciento sesenta pesos 00/100MN) por pareja</td>
                                                <td>Del 16 de septiembre al 4 de octubre</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Precio Taquilla</th>
                                                <td>$1400.00 (mil cuatrocientos pesos 00/100 MN) por persona</td>
                                                <td>$2500.00 (dos mil quinientos pesos 00/100MN) por pareja</td>
                                                <td>Taquilla día del evento 5 de octubre</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p class="pb-4 pt-5">El pago se realiza por medio de NAS.IO, terminos
                                y condiciones en NAS.IO</p>
                                <p class="pb-4"><strong>Tercero. Derechos.</strong> Una vez realizado el pago tienes derecho a asistir al evento. Es necesario que consultes y planifiques el horario y los traslados para que no te quedes sin asistir al evento.</p>
                                <p class="pb-4"><strong>Cuarto. Cancelaciones.</strong> BBMundo Direct S.A. de C.V. puede cancelar el ciclo de conferencias. En tal caso se notificará vía correo electrónico a la dirección que se haya proporcionado durante el registro, y se devolverá el precio pagado a la cuenta bancaria que nos indiques. BBMundo Direct S.A. de C.V. no se hace responsable en caso de que el número de cuenta proporcionado no corresponda a la persona que se haya registrado.</p>
                                <p class="pb-4">En caso de que la persona que se haya registrado, cancele o no asista al evento, BBMundo Direct S.A. de C.V. no realizará ningún reembolso, ni asumirá responsabilidad alguna.</p>
                                <p class="pb-4"><strong>Quinto. Condiciones generales.</strong>El pago para asistir al evento no incluye hospedaje ni alimentos. BBMundo Direct S.A. de C.V. no es responsable de los productos o servicios promocionados y comercializados en el sitio del evento.</p>
                                <p class="pb-4"><strong>Sexto. Difusión publicitaria.</strong> BBMundo Direct S.A. de C.V. puede transmitir fotografías y videos de los asistentes al evento, así como las encuestas, impresiones o entrevistas realizadas durante el evento, ya sea con fines promocionales o periodísticos. Al pagar este boleto y asistir al evento se entienden aceptados los presentes Términos y Condiciones y, por lo tanto, otogada de manera gratuita su autorización del uso de su imagen que pudiere aparecer en los mencionados materiales</p>
                                <p class="pb-4"><strong>Septimo. Aclaración y subsanación de errores.</strong>Cualquier error en el precio, conceptos, fechas, horarios, número de participantes u otro que implique un servicio o producto distinto o de mayor valor al precio real, o que de la simple lectura o comparación de precios en servicios iguales o similares sea evidente que se trata de un error, dará lugar a que BBMundo Direct S.A. de C.V. pueda corregir el precio, los conceptos, fechas, número de participantes o aquel error que haya sido detectado de manera previa a la realización del evento o a solicitud de aclaración de los asistentes al evento o adquirentes de boletos para asistir al evento. En caso de error, BBMundo Direct S.A. de C.V. solo estará obligada a la devolución del monto pagado sin incurrir en mayor responsabilidad.</p>
                                <p class="pb-4"><strong>Aviso de uso.</strong> Para el registro de los asistentes al evento de manera presencial o en línea, BBMundo Direct S.A. de C.V. solicitará y recabará datos personales, tales como: (i) Nombre completo; (ii) teléfono; (iii) correo electrónico; los cuales serán utilizados con fines estadísticos y publicitarios. Para mayor información sobre el tratamiento de los datos personales y el ejercicio de los derechos ARCO, consulta nuestra “Política de Privacidad” en el sitio electrónico <a href="https://masterbbmundo.com">https://masterbbmundo.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Terminos;