const Patrocinios = () => {
    return (
        <>
            <section class="patrocinadores  container-fluid px-0">
                <div class="container">
                    <h1 class="text-center title__patrocinadores pb-5 text-uppercase">Nuestros patrocinadores</h1>
                </div>
                <div class="container d-flex justify-content-center align-content-center">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 d-flex justify-content-center align-items-center mb-5">
                            <a href="https://www.sterimar.com.mx/" target="blank">
                                <img
                                    class="logo smallLogo"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/Sterimar.webp"
                                    alt="Sterimar"
                                    width="150"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 d-flex justify-content-center align-items-center">
                            <a href="https://volvamosaimprimir.com.mx/" target="blank">
                                <img
                                    class="logo smallLogo"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/fuji.png"
                                    alt="Fujifilm"
                                    width="200"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 d-flex justify-content-center align-items-center">
                            <img
                                class="logo smallLogo"
                                loading="lazy"
                                src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/PrevitaMon.webp"
                                alt="PrevitaMom"
                                width="200"
                                height="auto"
                            />
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 d-flex justify-content-center align-items-center">
                            <a href="https://www.mustela.com.mx/es" target="blank">
                                <img
                                    class="logo smallLogo"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/LogoMustela.png"
                                    alt="Mustela"
                                    width="200"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 d-flex justify-content-center align-items-center">
                            <a href="https://kiddies.com.mx/" target="blank">
                                <img
                                    class="logo smallLogo"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/Kiddies.png"
                                    alt="Kiddies"
                                    width="200"
                                    height="auto"
                                />
                            </a>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 d-flex justify-content-center align-items-center">
                            <a href="https://daclaf.com/pages/prominent?srsltid=AfmBOopGRtC5RCP-5UhkALieRhJk-WPx5pqpkYIKclXwZJ2j0q8f2AfH" target="blank">
                                <img
                                    class="logo smallLogo"
                                    loading="lazy"
                                    src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/Prominent.png"
                                    alt="Prominent"
                                    width="200"
                                    height="auto"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Patrocinios;