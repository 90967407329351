import { HashLink } from "react-router-hash-link";


const Header = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="fixed-top margenHeader">
                            <nav class="navbar bg-white">
                                <div class="container-fluid contenedorNav p-2">
                                    <div class="menu-drawer d-block d-sm-block d-md-none"> <HashLink to={"/#tickets"} className="menu-drawer">BOLETOS AQUÍ</HashLink></div>
                                    <HashLink to={"/#home"}>
                                        <img class="img-fluid pe-0 logoMasterHeader d-none d-sm-none d-md-block" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Header/LogoHoriz_MasterBbmundo.png" alt="logoBbmundo" width="300" height="auto" />

                                    </HashLink>
                                    <div className="row sizeContainer">
                                        <div className="col m-auto">
                                            <div className="menu-drawer d-none d-sm-none d-md-block text-center mx-auto"><HashLink to={"/#tickets"} className="menu-drawer">BOLETOS AQUÍ</HashLink></div>
                                        </div>
                                        <div className="col">
                                            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                                <span class="navbar-toggler-icon"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                                        <div class="offcanvas-header">
                                            <div class="offcanvas-title" id="offcanvasNavbarLabel" />
                                            <div className="close">
                                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            </div>
                                        </div>
                                        <div class="offcanvas-body">
                                            <ul class="navbar-nav justify-content-end flex-grow-1 p-4">
                                                <li class="nav-item">
                                                    <HashLink to={"/#home"}
                                                        className="nav-link"
                                                    >Inicio</HashLink>
                                                </li>
                                                <li class="nav-item">
                                                    <HashLink to={"/#lugar"}
                                                        className="nav-link"
                                                    >Lugar</HashLink>
                                                </li>
                                                <li class="nav-item">
                                                    <HashLink to={"/#tickets"}
                                                        className="nav-link"
                                                    >Boletos</HashLink>
                                                </li>
                                                <li class="nav-item">
                                                    <HashLink to={"/#especialistas"}
                                                        className="nav-link"
                                                    >Especialistas</HashLink>

                                                </li>
                                                <li class="nav-item">
                                                    <HashLink to={"/#agenda"}
                                                        className="nav-link"
                                                    >Agenda</HashLink>

                                                </li>
                                                <li class="nav-item">
                                                    <HashLink to={"/#faqs"}
                                                        className="nav-link"
                                                    >Faq's</HashLink>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default Header;