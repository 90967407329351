import Inicio from "../Components/Inicio";
import Patrocinadores from "../Components/Patrocinadores";
import Time from "../Components/Time";
import IconsInfo from "../Components/IconsInfo";
import Boletos from "../Components/Boletos";
import Espacialistas from "../Components/Especialistas";
import Horarios from "../Components/Horarios";
import Faqs from "../Components/Faqs";

const Main = () => {
    return (
        <>
            <Inicio />
            <Time />
            <Patrocinadores />
            <IconsInfo />
            <Boletos />
            <Espacialistas />
            <Horarios />
            <Faqs />
        </>
    )
}
export default Main;