const Footer = () => {
    return (
        <>
            <footer>
                <div class="container-fluid terms text-white position-relative">
                    <img class="decorationFooter1" src="https://masterbbmundo.s3.amazonaws.com/Footer/Cubitos_2.png" alt="decoration1" width="400px" height="auto" />
                    <img class="decorationFooter2" src="https://masterbbmundo.s3.amazonaws.com/Footer/Cubitos_1.webp" alt="decoration2" width="400px" height="auto" />
                    <a class="pe-3 textFoot" target="blank" href="/avisoDePrivacidad">Aviso de privacidad </a>| <a class="ps-3 textFoot" target="blank" href="/terminosYcondiciones">Términos y condiciones</a>
                    <a class="pe-3 textFoot d-block pt-3" target="blank" href="https://nas.io/es-mx/privacy">
                        Política de privacidad NAS.IO
                    </a>
                </div>
            </footer>
        </>
    )
}
export default Footer;