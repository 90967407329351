

const Patrocinadores = () => {

    /*useEffect(()=>{
        var copy = document.querySelector(".logos-slide").cloneNode(true);
        console.log(copy);
        document.querySelector(".logo-slider").appendChild(copy);
    })*/

    

    return (
        <>
            <div id="lugar" class="location container-fluid p-0">
                <div class="headerLogos py-3">
                    <div class="logo-slider">
                        <div class="logos-slide">
                            <a class="text-decoration-none" href="https://www.sterimar.com.mx/" target="blank">
                                <img class=" logo smallLogo" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/Sterimar.webp" alt="Sterimar" width="150" height="auto" />
                            </a>
                            <a class="text-decoration-none" href="https://volvamosaimprimir.com.mx/" target="blank">
                                <img class=" logo smallLogo" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/fuji.png" alt="Fujifilm" width="200" height="auto" />
                            </a>
                            <a class="text-decoration-none" href="" target="blank">
                                <img class=" logo smallLogo" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/PrevitaMon.webp" alt="kiddies" width="200" height="auto" />
                            </a>
                            <a class="text-decoration-none" href="https://kiddies.com.mx/" target="blank">
                                <img class=" logo smallLogo" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/Kiddies.png" alt="kiddies" width="200" height="auto" />
                            </a>
                            <a class="text-decoration-none" href="https://www.mustela.com.mx/es" target="blank">
                                <img class=" logo smallLogo" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/LogoMustela.png" alt="Mustela" width="200" height="auto" />
                            </a>
                            <a class="text-decoration-none" href="https://daclaf.com/pages/prominent?srsltid=AfmBOopGRtC5RCP-5UhkALieRhJk-WPx5pqpkYIKclXwZJ2j0q8f2AfH
" target="blank">
                                <img class=" logo smallLogo" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Patrocinadores/Prominent.png" alt="Prominent" width="200" height="auto" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="containerLocation row" name="lugar">
                    <div class="col-12 col-md-5 col-lg-5 px-0 px-md-0 px-lg-4">
                        <div class="d-flex align-items-center position-relative justify-content-center">
                            <img class="d-inline py-0 pe-3 decoration1" loading="lazy" src="https://masterbbmundo.s3.amazonaws.com/Lugar/Ico_Elefante.png" alt="Elefante" width="230" height="auto" />
                            <h1 class="titleLocation text-capitalize d-inline mb-0">Dónde:</h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4 px-1">
                        <h5 class="subtitleLocation mb-3">ESPACIO DURANGO</h5>
                        <p class="addressText">Calle de Durango 275, Roma Nte, Cuauhtémoc, 06700, CDMX.</p>
                    </div>

                    <div class="col-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
                        <a class="text-decoration-none" href="https://www.waze.com/es-419/live-map/directions/mx/cdmx/mexico-d.f./durango-275?to=place.ChIJb0Me8d__0YURMU_ozCw_jxc" target="blank">
                            <img class="me-3 waze" src="https://masterbbmundo.s3.amazonaws.com/Lugar/Ico_Waze.png" alt="waze" width="40px" height="auto" />
                        </a>
                        <a class="text-decoration-none" href="https://www.google.com.mx/maps/dir//Durango+275,+Calle+de+Durango,+Roma+Norte,+Ciudad+de+M%C3%A9xico,+CDMX/@19.4192281,-99.2118463,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x85d1ffdff11e436f:0x178f3f2ccce84f31!2m2!1d-99.1706475!2d19.4192357!3e0?entry=ttu" target="blank">
                            <img class="me-3 mapa" src="https://masterbbmundo.s3.amazonaws.com/Lugar/Ico_Mapa.png" alt="waze" width="40px" height="auto" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Patrocinadores;