import React, { useRef, useState } from "react";
import Patrocinios from "./Patrocinios";

const Video = () => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            videoRef.current.muted = false;
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            videoRef.current.muted = true;
            setIsPlaying(false);
        }
    };

    return (
        <>
            <div id="location" class="videoSection container-fluid">
                <div className="container">
                    <div class="row pb-2 pb-md-5">
                        <h1 class="titleVideo text-center mb-5 px-5 text-uppercase">Esto es lo que vivirás en Master Bbmundo</h1>
                    </div>
                </div>
                <div className="conatainer">
                    <div class="row pt-3 pt-md-5 position-relative p-3">
                        <section id="masterBbmundoVideo" class="prevVideo col-12">
                            <div class="containerVideoprev container position-relative px-0 px-md-5">
                                <video muted
                                    loop
                                    playsInline
                                    id="videoPrev"
                                    className="position-relative"
                                    ref={videoRef}
                                    onClick={togglePlay}>
                                    <source src="https://masterbbmundo.s3.amazonaws.com/video/video+bbmundo.mp4" />
                                </video>
                                <img
                                    width="50"
                                    height="auto"
                                    loading="lazy"
                                    class="iconPlay"
                                    src="https://masterbbmundo.s3.amazonaws.com/video/Icono_Play.webp"
                                    alt="play"
                                    style={{ display: isPlaying ? "none" : "block" }}
                                />
                            </div>
                            <div>
                            </div>
                        </section>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div class="bgVideo d-block"></div>
                                </div>
                            </div>
                        </div>
                        <img class="luces1" src="https://masterbbmundo.s3.amazonaws.com/video/BackIzquierdo_Video.webp" alt="luces" width="100px" height="auto" />
                        <img class="luces2" src="https://masterbbmundo.s3.amazonaws.com/video/BackDerecho_Video.webp" alt="luces" width="100px" height="auto" />
                    </div>
                </div>

                <div className="container">
                    <div class="row">
                        <p class="paragraphVideo text-center mt-5">En <span>Master bbmundo </span>te vas a llevar una gran cantidad de aprendizajes y habilidades prácticas para afrontar los desafíos y las alegrías de crecer como familia.</p>
                    </div>
                </div>
            </div>
            <Patrocinios />
        </>
    )
}
export default Video;